.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: transparent;

    width: 0;
    /* Ancho cero para hacer un triángulo */
    height: 0;


    /* Alto cero para hacer un triángulo */
    border-left: 20px solid transparent;
    /* Lado izquierdo del triángulo */
    border-right: 20px solid transparent;
    /* Lado derecho del triángulo */
    border-bottom: 30px solid #fff;
    /* Parte inferior del triángulo */
    display: flex;
    justify-content: center;
    align-items: center;
    color: transparent;
    /* Establecer el color del contenido como transparente */
    transition: background-color 0.3s ease;
    transform-origin: center;
    /* Establecer el origen de la transformación en el centro */

    &:hover {
        background-color: rgba(255, 255, 255, 0.7);
    }
}

.next {
    z-index: 1;
    right: 10px;
    transform: rotate(90deg);
    /* Rotación de 0 grados para mirar a la derecha */
}

.prev {
    z-index: 1;

    left: 10px;
    transform: rotate(270deg);
    /* Rotación de 180 grados para mirar a la izquierda */
}

/* Estilos para el logotipo */
.logo-img {
    position: absolute;
    top: 1px;
    /* Ajusta la posición vertical según tu preferencia */
    right: 1px;
    /* Ajusta la posición horizontal según tu preferencia */
    width: 180px;
    /* Ajusta el ancho según tu preferencia */
    height: auto;
    /* Ajusta la altura automáticamente para mantener la proporción */
}

/* Estilos para el botón */
.button-svg {
    /* Estilos predeterminados del botón aquí */
}

/* Estilos para el botón cuando el color es 'rojo' */
.red-button {
    fill: #39A6DF;
}

/* Estilos para el botón cuando el color es 'azul' */
.blue-button {
    fill: #72B650;
}

/* Estilos para el botón cuando el color es 'verde' */
.green-button {
    fill: #fe62b1;
}

/* Agrega más estilos para otros colores si es necesario */
.logo-and-svg-container {
    display: flex;
    align-items: center;
    /* Centra verticalmente los elementos */
    justify-content: flex-end;
    /* Alinea a la derecha */
    z-index: 20;


}

.logo-and-svg-container img {
    width: 300px;
    /* Ajusta el valor según el tamaño que desees */
    height: auto;
    /* Mantiene la proporción de aspecto */


}




.ingles-and-svg-container {
    display: flex;
    align-items: center;
    /* Centra verticalmente los elementos */
    justify-content: flex-end;
    /* Alinea a la derecha */
    top: 190px;
    /* Ajusta la posición vertical según tu preferencia */
    right: 35%;
    /* Ajusta la posición horizontal al 35% desde la derecha */
}


.CustomSVG-and-svg-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 172px;
    right: 1px;
}

/* Nuevos estilos para ocultar en pantallas más pequeñas */
@media (max-width: 767px) {
    .CustomSVG-and-svg-container {
        display: none;
    }
}


.Redes-and-svg-container {
    align-items: center;
    /* Centra verticalmente los elementos */
    justify-content: flex-end;
    /* Alinea a la derecha */
    position: absolute;
    top: 252px;
    /* Ajusta la posición vertical según tu preferencia */
    right: 1px;
    /* Ajusta la posición horizontal según tu preferencia */
}



.Redes-whatsaap-and-svg-container {
    align-items: center;
    /* Centra verticalmente los elementos */
    justify-content: flex-end;
    /* Alinea a la derecha */
    position: absolute;
    top: 210px;
    /* Ajusta la posición vertical según tu preferencia */
    right: 1px;
    /* Ajusta la posición horizontal según tu preferencia */
}



.Redes-facebook-and-svg-container {
    align-items: center;
    /* Centra verticalmente los elementos */
    justify-content: flex-end;
    /* Alinea a la derecha */
    position: absolute;
    top: 485px;
    /* Ajusta la posición vertical según tu preferencia */
    right: 1px;
    /* Ajusta la posición horizontal según tu preferencia */
}


.Redes-instagram-and-svg-container {
    align-items: center;
    /* Centra verticalmente los elementos */
    justify-content: flex-end;
    /* Alinea a la derecha */
    position: absolute;
    top: 540px;
    /* Ajusta la posición vertical según tu preferencia */
    right: 1px;
    /* Ajusta la posición horizontal según tu preferencia */
}

.logo-img {
    width: 100px;
    /* Ancho de la imagen del logo (ajusta según tus necesidades) */
    height: auto;
}

/* Estilos para el SVG */
.button-svg {
    margin-left: 10px;
    /* Espacio entre el logo y el SVG (ajusta según tus necesidades) */
}

.vison {
    zIndex: 1;

}

/* Estilos para pantallas más grandes, como tabletas y computadoras */
.banner {
    /* Tus estilos actuales para tamaños de pantalla más grandes */
}





/* Estilos para pantallas más pequeñas, como celulares */
@media (max-width: 767px) {
    .banner {
        /* Estilos específicos para pantallas de celular */

        /* Haz que los botones sean más pequeños y estén más cerca */
        .buttons-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .descubreButtonStyle,
        .conoceMasButtonStyle {
            width: 12px;
            /* Ancho más pequeño */
            height: 30px;
            /* Alto más pequeño */
            margin-bottom: 10px;
            /* Espacio entre los botones */
        }


        .mobileStyle {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

    }
}

.footer-container {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-section {
    width: 30%;
    margin-bottom: 20px;
}

.footer-section h4 {
    color: #72B650;
    /* Color de título */
}

.copyright {
    margin-top: 20px;
}

/* Est/* Estilos para pantallas más pequeñas, como celulares */
@media (max-width: 767px) {
    .banner {
        /* Estilos específicos para pantallas de celular */

        /* Haz que los botones sean más pequeños y estén más cerca */
        .buttons-container {
            display: flex;
            flex-direction: column;
            /* Cambia la dirección del flex a columna */
            align-items: center;
        }

        .descubreButtonStyle,
        .conoceMasButtonStyle {
            width: 100%;
            /* O el ancho que desees */
            height: 40px;
            /* O el alto que desees */
            margin-bottom: 10px;
            /* Espacio entre los botones */
        }

        /* Oculta el texto pink en la pantalla de celular */

        /* Oculta el texto pink en la pantalla de celular */

    }

    /* Agrega estilos adicionales según sea necesario para pantallas más pequeñas */
}