body {
    background-image: url('../../Img/quienessomos/rascacielos-al-atardecer.jpg.png');
    /* Reemplaza 'ruta/de/imagen-de-fondo.jpg' con la ruta real de tu imagen */
    background-attachment: fixed;
    background-size: cover;
    /* Esto ajusta el tamaño de la imagen para cubrir todo el fondo */
    background-repeat: no-repeat;
    /* Esto evita que la imagen de fondo se repita */
}

/* Elimina cualquier estilo que afecte las tarjetas */
.cardofertas {
    width: 100%;
    padding-bottom: 100%;
    /* Esto hace que la tarjeta tenga un aspecto cuadrado */
    color: rgba(229, 0, 0, 0.34);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 40px;
    /* Ajusta este valor para cambiar el nivel de redondeo */
    box-shadow: 0 0 15px rgba(254, 17, 0, 0.912);
    /* Ajusta el valor 10px para controlar la intensidad de la sombra */
}


.cardofertas-text {
    position: absolute;
    top: 80%;
    /* Alinea la parte superior del texto al 80% del contenedor */
    left: 50%;
    /* Alinea la parte izquierda del texto al 50% del contenedor */
    transform: translate(-50%, -50%);
    /* Centra el contenido horizontal y verticalmente */
    background-color: rgba(0, 0, 0, 0.648);
    padding: 9px;
    text-align: center;
    /* Alinea el texto en el centro horizontalmente */
    color: white;
    /* Cambia el color del texto a blanco */

}



.cardofertas-title {
    /* Cambia el color del texto a blanco */

    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
}

.cardofertas-text p {
    /* Cambia el color del texto a blanco */

    text-transform: lowercase;
    font-size: 16px;
}


h1 {
    font-weight: bold;
    /* Hace que el texto sea más grueso */
    text-shadow: 2px 2px 4px rgb(0, 76, 254, 0.912);
    /* Agrega un efecto de sombra con color blanco para simular iluminación */
    color: #5309ff;
    /* Establece el color del texto en blanco */
}


h1.Title-cash {
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(131, 191, 255, 0.747);
    /* Cambia "rgb(0, 76, 254, 0.912)" por "red" */
    color: #ffffff;
}

h1.ofertas-title {
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(230, 121, 32, 0.747);
    /* Cambia "rgb(0, 76, 254, 0.912)" por "red" */
    color: #ff0000;
}

/* Estilo personalizado para el modal */
.custom-modal {
    background-color: rgba(7, 7, 7, 0.866);
    /* Fondo blanco semi-transparente */
    border-radius: 10px;
    /* Esquinas redondeadas */
    box-shadow: 0px 0px 10px 0px rgb(180, 50, 50);
    /* Sombra ligera */
}

/* Estilo personalizado para el texto dentro del modal */
.modal-text {
    font-size: 16px;
    color: #000000;
    /* Color de texto oscuro */
}

/* Agrega más estilos para otros colores si es necesario */
.logo-and-svg-containers {
    display: flex;
    /* Centra verticalmente los elementos */


}

.logo-and-svg-containers img {
    width: 200px;
    /* Ajusta el valor según el tamaño que desees */
    height: auto;
    /* Mantiene la proporción de aspecto */


}

/* Estilos para el botón de WhatsApp flotante */
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #9c9c9c;
    /* Color verde de WhatsApp */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
    background-color: #128c7e;
    /* Cambia el color al pasar el mouse sobre el botón */
}

/* Agrega estas reglas de estilo a tu hoja de estilos CSS */

/* Estilo predeterminado del calendario */
.react-calendar {
    /* Establece el ancho máximo y centra el calendario */
    max-width: 300px;
    /* Puedes ajustar este valor según tus necesidades */
    margin: 0 auto;
}

/* Consulta de medios para dispositivos móviles (ancho máximo de 600px) */
@media (max-width: 750px) {
    .react-calendar {
        /* Ajusta el ancho máximo para dispositivos móviles más pequeños */
        max-width: 300%;
        margin: 0 auto;

        /* O cualquier otro valor que desees */
    }
}

/* Estilos para dispositivos móviles */
@media only screen and (max-width: 600px) {
    .mobile-view {
        display: flex;
        /* Utiliza flexbox para alinear elementos horizontalmente */
        flex-direction: column;
        /* Alinea los elementos en una columna */
        align-items: center;
        /* Centra horizontalmente los elementos */
        text-align: center;
        /* Centra el texto horizontalmente */
    }
}