/* Fondo principal */
body {
    background-image: url('../../Img/quienessomos/rascacielos-al-atardecer.jpg.png');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}


.white-text {
    color: #ffffff;
}


/* Elimina cualquier estilo que afecte las tarjetas */
.carddestinos {
    width: 100%;
    padding-bottom: 100%;
    color: rgba(229, 0, 0, 0.34);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 40px;
    box-shadow: 0 0 15px rgba(0, 76, 254, 0.912);
}

.carddestinos-text {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.648);
    padding: 9px;
    text-align: center;
    color: white;
}

.carddestinos-title {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
}

.carddestinos-text p {
    text-transform: lowercase;
    font-size: 16px;
}

h1 {
    font-weight: bold;
    text-shadow: 2px 2px 4px rgb(0, 76, 254, 0.912);
    color: #5309ff;
}





/* Agrega más estilos para otros colores si es necesario */
.logo-and-svg-containers {
    display: flex;
}

.logo-and-svg-containers img {
    width: 200px;
    height: auto;
}

/* Estilos para el botón de WhatsApp flotante */
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
    background-color: #128c7e;
}

/* Modal.css */
.modal-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.938);
    justify-content: center;
    align-items: center;
    z-index: 8;
    overflow-y: auto;
    /* Agrega scroll vertical cuando es necesario */
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-height: 80vh;
    /* Ajusta la altura máxima del modal según tus necesidades */
    overflow-y: auto;
    /* Agrega scroll vertical al contenido del modal */
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    background-color: #fff;
}

/* ... (resto del código) */

/* Nuevos estilos para las opciones del modal */
.modal-options {
    display: flex;
    margin-bottom: 15px;
}

.modal-option {
    cursor: pointer;
    padding: 10px;
    margin-right: 10px;
    border: 5px solid #5309ff6e;
    border-radius: 5px;
}

.modal-option.active {
    background-color: #5309ff;
    color: #fff;
}

h1.Title-cash {
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(131, 191, 255, 0.747);
    /* Cambia "rgb(0, 76, 254, 0.912)" por "red" */
    color: #ffffff;
}

/* Estilos para el contenido dinámico del modal */
.modal-content p {
    color: #fff;
}

/* Estilos para el calendario en la parte central derecha */
.modal-calendar {
    float: center;
    width: 30%;
    padding: 10px;
    background-color: #fff;
    /* Ajusta el color de fondo según tus necesidades */
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.3);
}

.h1modal {
    color: #fff;
    /* Asegura que el color del texto sea blanco */
    text-align: center;
    /* Alinea el texto al centro si es necesario */
    /* Agrega otros estilos según tus necesidades */
}

.h2info {
    font-weight: bold;
    text-shadow: 2px 5px 4px rgba(0, 76, 255, 0.912);
    color: #ffffff;
    text-align: center;
    font-size: 44px;
    /* Ajusta el tamaño de la fuente según tus necesidades */
    /* Otros estilos según tus necesidades */
}

/* Estilo para el campo de entrada de cantidad de personas */
.cantidad-personas-section {
    margin-bottom: 15px;
}

/* Estilo para el precio total */
.precio-total-section {
    margin-bottom: 15px;
}

/* Estilo para los botones */
.modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.modal-buttons button {
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
}

.modal-buttons button:hover {
    background-color: #45a049;
}

/* Estilos para los sliders y otros elementos visuales */
.slider-container {
    display: flex;
    align-items: center;
}

.slider-container input {
    width: 80px;
    margin: 0 10px;
}

.slider-container span {
    font-weight: bold;
}

.person-icon img,
.price-icon img {
    width: 50px;
    height: 40px;
    margin-right: 10px;
}

/* Añade transiciones y animaciones según sea necesario */