/* styles.css */
body {
    margin: 0;
    padding: 0;
    overflow: auto;
    /* Habilita el desplazamiento vertical */
}


.background-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    /* Oculta el desbordamiento del contenido */
}

.background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* Coloca la imagen de fondo detrás de otros elementos */
}

.menu-container {
    position: absolute;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    top: 0;
    left: 0;
}

.overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    /* Capa transparente un poco más oscura */
}

.cards-container {
    position: relative;
}



/* Nuevos estilos para ocultar en pantallas más pequeñas */
@media (max-width: 767px) {
    .cards {
        display: none;
    }

    .logo-and-svg-container {
        align-items: center;
    }

    .salto::after {
        content: "\A \A \A \A \A";
        white-space: pre;
    }


    .mobile-container {
        display: block;
    }

    .paragraphStyle {
        textAlign: left,

    }

    .boldText {
        text-align: left
    }
}




.cards {
    width: calc(30% - 25px);
    position: absolute;
    margin-bottom: 10px;
    background-color: white;
    /* Fondo blanco */
    color: black;
    /* Texto negro */
    border-radius: 15px;
    /* Ajusta según sea necesario */
    overflow: hidden;
    /* Oculta el desbordamiento de las esquinas redondeadas */
    transition: transform 0.3s ease-in-out;
    /* Agrega una transición suave al transformar las tarjetas */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* ... (otros estilos) */
    margin: 0 10px 20px 10px;
    /* Márgenes externos para separar las tarjetas verticalmente y horizontalmente */
    padding: 20px;
    text-align: left;
    /* Ajusta el texto a la izquierda para una alineación uniforme */
    padding: 20px;
}

.mobile-buttonsb {
    display: none;

}

.cards:hover {
    transform: scale(1.05);
    /* Escala ligeramente la tarjeta al pasar el mouse para un efecto interactivo */
}

.cards img {
    width: 100%;
    height: auto;
}

/* 
.card-container {
    position: relative;
}

.card {
    position: absolute;
    width: calc(30% - 25px);
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 10px;
}

.card img {
    width: 100%;
    height: auto;
} */
.custom-h1 {
    font-weight: bold;
    /* Hace que el texto sea más grueso */
    text-shadow: 2px 2px 4px rgba(255, 59, 15, 0.912);
    /* Agrega un efecto de sombra con color blanco para simular iluminación */
    color: #ff0909;
    /* Establece el color del texto en blanco */
}

/* Estilos para el popup */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    text-align: center;
}

.popup-content img {
    max-width: 100%;
    height: auto;
}

.popup-content h2 {
    margin-top: 0;
}