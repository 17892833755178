body {
    background-image: linear-gradient(rgba(0, 145, 255, 0.192), rgba(192, 28, 28, 0));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

h1,
h2,
h3 {
    font-weight: bold;
    margin-bottom: 10px;
}

p {
    margin-bottom: 10px;
}

button {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
}

button:hover {
    background-color: #ddd;
}

.container {
    max-width: 1000px;
    margin: 0 auto;
}

.section {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.form-group {
    margin-bottom: 10px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
}

input,
select {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
}

.header {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
}

.header h1 {
    margin: 0;
}

.footer {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
    margin-top: 20px;
}

.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
}

th,
td {
    padding: 5px 10px;
    border: 1px solid #ccc;
}

th {
    text-align: center;
}

.actions {
    text-align: right;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: none;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #ccc;
    font-size: 20px;
}