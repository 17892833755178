.login-container {
    display: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* Replace with your background image */
    background-size: cover;
    background-position: center;
    font-family: sans-serif;
    /* Choose a futuristic font if desired */
    color: #fff;
    /* Light text for dark background */
}

.login-form {
    padding: 30px;
    border-radius: 10px;
    /* Rounded corners */
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent dark background */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    /* Subtle shadow */
}

h1 {
    font-size: 3em;
    margin-bottom: 20px;
    text-align: center;
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
    /* Text glow */
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    /* Rounded input fields */
    background-color: rgba(64, 62, 223, 0.2);
    /* Light input background */
    color: #3c1eff;
    /* Light text for input */
    margin-bottom: 15px;
}

input[type="text"]:focus,
input[type="password"]:focus {
    outline: none;
    /* Remove default focus outline */
    background-color: rgba(255, 255, 255, 0.3);
    /* Slight emphasis on focus */
}

button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    /* Blue button */
    color: #fff;
    /* White text */
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

button:hover {
    background-color: #0069d9;
    /* Darker blue on hover */
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}