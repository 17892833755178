/* Estilos para el menú */
.menu {
    top: 0;
    left: 0;

    z-index: 2;
    position: absolute;

    background-color: transparent;
    /* Fondo transparente */
    display: flex;
    /* Alinea el menú a la derecha en PC */
    align-items: center;
    padding: 10px 20px;
    color: #fff;
}

/* Estilos para los enlaces en el menú */
.menu-items a {
    text-decoration: none;
    color: #fff;
    margin: 0 10px;
    /* Espaciado entre elementos de menú */
    font-family: 'Montserrat', sans-serif;
    /* Utiliza la fuente Montserrat */
    font-weight: bold;
    /* Usa la variante Bold de Montserrat */
    font-size: 18px;
    /* Tamaño de fuente 22px */
}

/* Media query para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
    /* ... tus otros estilos ... */

    /* Estilos adicionales para el menú desplegado en dispositivos móviles */

    .a {
        font-size: 15;
    }

    .menu-items.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: #ff0000;
        /* Fondo gris */
        width: 50%;
        /* Ocupa todo el ancho de la pantalla */
    }
}

/* Estilos para las líneas de menú en dispositivos móviles */
.menu-icon {
    display: none;
    cursor: pointer;
    font-size: 24px;
}

/* Estilos para los elementos del menú en dispositivos móviles */
.menu-items {
    display: flex;
    flex-direction: row;
    /* Cambia la dirección a horizontal en dispositivos móviles */
    text-align: center;
}

/* Estilos para los enlaces en el menú */
.menu-items a {
    text-decoration: none;
    color: #fff;
    margin: 0 10px;
    /* Espaciado entre elementos de menú */
}

/* Media query para pantallas pequeñas (móviles) */
@media (max-width: 768px) {

    .a {
        font-size: 15;
    }

    /* Muestra las líneas de menú en dispositivos móviles */
    .menu-icon {
        display: block;
    }

    /* Oculta el menú desplegado en dispositivos móviles */
    .menu-items {
        display: none;
    }

    /* Estilos adicionales para el menú desplegado en dispositivos móviles */
    /* Estilos adicionales para el menú desplegado en dispositivos móviles */
    .menu-items.active {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        /* Alinea el menú desplegado desde la izquierda en dispositivos móviles */
        background-color: #222222d3;
        /* Fondo transparente en dispositivos móviles */
        width: 100%;
        /* Ocupa todo el ancho de la pantalla */
        height: 100vh;
        /* Ocupa toda la altura de la pantalla */
        font-size: 14px;
        z-index: 1;
    }

}

/* Estilos para la lupa predeterminada (rosa) */
.lupa {
    fill: pink;
    /* Cambia el color de relleno a rosa */
    transition: fill 0.3s ease;
    /* Agrega una transición suave para el cambio de color */
}

/* Estilos para la lupa cuando está sobre img1 (azul) */
.img1 {
    fill: #39A6DF;
}

/* Estilos para la lupa cuando está sobre img2 (rosa) */
.img2 {
    fill: #72B650;
}

/* Estilos para la lupa cuando está sobre img3 (verde) */
.img3 {
    fill: rgb(254, 98, 177);
}


/* Estilos para los enlaces en el menú */
.letras a {
    text-decoration: none;
    color: #e8edf3;
    margin: 0 10px;
    font-size: 16px;
    /* Ajusta el tamaño de la fuente según tus preferencias */
}

.letras-row {
    display: flex;
    flex-wrap: wrap;
}

.letras {
    /* Estilos para cada elemento de país, ajusta según sea necesario */
    margin: 5px;
}

.destinos-dropdown {
    background-color: rgba(0, 0, 0, 0);
    /* Negro totalmente transparente inicialmente */
    position: absolute;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    z-index: 1;
    /* Asegura que el menú desplegado esté encima del resto del contenido */
    transition: background-color 0.3s ease;
    /* Agrega una transición suave para el cambio de color */
}

.destinos-dropdown.active {
    background-color: rgba(0, 0, 0, 0.7);
    /* Negro semi transparente cuando el menú está activo */
}

/* Agrega esto a tu archivo CSS */
.search-result-item {
    cursor: pointer;
    padding: 10px;
    /* Ajusta el espaciado según tus preferencias */
    border-bottom: 1px solid #cccccc08;
    /* Línea divisoria entre resultados */
}

.search-result-item:hover {
    background-color: #665f965a;
    /* Cambia el color de fondo al pasar el ratón sobre un resultado */
}